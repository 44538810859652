<template>
  <div>

    <div class="container  titleContainer">
      <div class="row">
        <div class="col-md-6 col-sm-9 createCardTitleWrapper">
          <b-card id="createCardTitle">
            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="none" to="/assets/data-list" v-b-tooltip.hover
              title="Go Back to the Search List" class="d-inline text-warning">
              <feather-icon icon="ArrowLeftCircleIcon" size="25" />
            </b-button>
            <h3 class="d-inline text-center font-weight-bolder">Add a new data source</h3>
          </b-card>
        </div>
      </div>
    </div>
    <b-card id="createCardForm">
      <div class="container">
        <form @submit.prevent="storeJson">
          <div class="row justify-content-center">
            <div class="col-md-10 my-1">
              <b-form-group label="Title">
                <b-form-input v-model="title" required placeholder="Enter a title for the json file"></b-form-input>
              </b-form-group>
            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col-md-10 my-1">
              <b-form-textarea v-model="description" rows="4" required
                placeholder="Enter a description"></b-form-textarea>
            </div>

            <div class="col-md-10 my-1">
              <b-form-group label="JSON File">
                <b-form-file v-model="jsonFile" accept=".json" placeholder="Choose a JSON file"></b-form-file>
                <small class="form-text text-muted">Accepted formats: JSON</small>
              </b-form-group>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10 mb-2">
              <b-form-group label="List of Apps Associated with this file (Choose from available Package names)">
                <v-select v-model="appsid" :options="appsidlist" multiple></v-select>
              </b-form-group>

            </div>


            <div class="col-md-3">
              <b-button type="submit" variant="outline-success" block>add data source</b-button>
            </div>
          </div>
        </form>
      </div>
    </b-card>
    <div class="loader-overlay" v-if="isLoading">
      <b-spinner label="Loading..." style="width: 6rem; height: 6rem;" variant="success" />
    </div>
  </div>
</template>

<script>
import {
  BContainer,
  BImg,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BLink,
  BButton,
  BFormFile,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import vSelect from 'vue-select'
export default {
  components: {
    BFormTextarea,
    BLink,
    'b-tooltip': VBTooltip,
    BCard,
    BImg,
    'v-select': vSelect,
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      title: '',
      description: '',
      appsid: [],
      zip_url: '',
      jsonFile: null,
      appsidlist: [],
      selectedApps: [],
      searchText: '',
      isLoading: false,
    }
  },
  mounted() {
    const userType = JSON.parse(localStorage.getItem('userData')).role;
    if (userType !== "user") {
      router.push({ name: "error-404" })
    }
    this.user_token = JSON.parse(localStorage.getItem("accessToken"));
    this.getApps();
  },
  methods: {
    getApps() {
      axios.get('/get-appsids').then(({ data }) => {
        data.map((x) => {
          this.appsidlist.push(x.appId)
        })
      })
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    storeJson() {
      try {
        var formData = new FormData();
        formData.append("title", this.title);
        formData.append("description", this.description);
        formData.append("user_token", this.user_token);
        formData.append("appsId[]", this.appsid);
        formData.append("json_file", this.jsonFile);
        this.isLoading = true
        axios.post('/assets/data/create', formData)
          .then(({ data }) => {
            if (data.status == "success") {
              this.isLoading = false
              // this.isLoading = false
              this.showToast(
                "success",
                "SaveIcon",
                "Success",
                data.message
              );
              router.push({ name: 'am-assets-data' })
            } else if (data.status == "failed") {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                data.message
              );
              this.isLoading = false

            }
          })
          .catch((error) => {
            this.isLoading = false

            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              error
            );
          });
      } catch (error) {
        this.isLoading = false
        console.error('Error uploading files:', error);
      }

    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
#createCardTitle,
#createCardForm,
.createCardTitleWrapper,
[dir] .card,
.titleContainer {
  margin: 0 !important;

}



#createCardTitle,
#createCardForm {
  border-radius: 0 !important;
}

div.app-content.content>div.content-wrapper>div>div>div.container.titleContainer>div>div {
  padding: 0;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  /* Add blur effect to the background */
}

/* Center the spinner */
.loader-overlay .spinner-grow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>